import {produce} from "immer"
import {
  RECEIVE_SIGNIN_DATA,
  RECEIVE_PRODUCT_LIST,
  RECEIVE_BARCODE_TYPE,
  RECEIVE_STORE_TYPE,
  RECEIVE_UNIT_OF_MEASURE,
  RECEIVE_PRODUCT_DETAILS,
  RECEIVE_PRODUCT_PARAMS,
  RECEIVE_CATEGORY_LIST,
  RECEIVE_SUB_CATEGORY_LIST,
  RECEIVE_PRODUCT_GROUP,
  RECEIVE_PRODUCT_GROUP_EDIT_PARAMS,
  RECEIVE_PRODUCT_GROUP_DETAILS,
  RECEIVE_PRODUCT_DELETE_GROUP,
  RECEIVE_ORDERS_LIST,
  RECEIVE_ORDERS_DETAILS_DATA,
  RECEIVE_LOCATION_LIST,
  RECEIVE_SELECTED_LOCATION,
  RECEIVE_TURNSTILE_LIST,
  RECEIVE_GONDOLA_LIST,
  RECEIVE_GONDOLA_SELECTED,
  RECEIVE_CAMERA_LIST,
  RECEIVE_GONDOLA_DETAILS,
  RECEIVE_LOCATION_SETUP,
  RECEIVE_STORE_DIMENSION_STEP,
  RECEIVE_GONDOLA_STEP,
  RECEIVE_CAMERA_AND_GPU_STEP,
  RECEIVE_CART_MANAGER_STEP,
  SET_USER_ROLE_INFORMATION,
  SET_USER_DECODE_DATA,
  USER_KEYCLOAK_ATTRIBUTES,
  USER_KEYCLOAK_BEFORE_MODIFY_ATTRIBUTES,
  SET_SMART_DEVICE_CONFIG_LOC,
  GET_SELECTED_SDC_LOCATION,
  SET_SMART_DEVICE_CONFIG_LOC_NAME,
  SET_CREATE_SDC_SUCCESS_TOGGLE,
  GET_INVENTORY_LOCATION,
  TRAINING_PRODUCTS_INFO,
} from "./constants"

export const initialState = {
  checking: [],
  productList: [],
  storeIDlist: [],
  unitOfMeasures: [],
  barCodeType: [],
  productDetailsData: [],
  productParams: [],
  catergoryList: [],
  subCategoryList: [],
  productGroupList: [],
  productGroupParams: [],
  groupDetails: [],
  productDelete: false,
  ordersList: [],
  orderDetails: [],

  locationListData: [],
  selectedLocationData: [],
  turstileListData: [],
  gondolaList: [],
  selectedGondola: [],
  gondolaSelected: [],
  cameraList: [],
  gondolaDetails: [],
  inventoryObjectData: {
    locObj: {},
    locId: "",
  },
  trainingProductInfo: {
    selectedMDTLocObj: {},
    selectedPVLocObj: {},
  },
  smartDeviceConfig: {
    turnstileLoc: "",
    gondolaLoc: "",
    cameraLoc: "",
    SmartShelfLoc: "",
    storeConfigLoc: "",
    storeConfigLocName: "", // above for maintain indiviually maintain
    SDCLocationId: "", //To maintain all the SDC Location
    FS_SDCLocId: "",
  },
  locationObjectData: {
    storeConfigLoc: [],
    turnstileLoc: [],
    gondolaLoc: [],
    cameraLoc: [],
    SmartShelfLoc: [],
    SDCLocationObject: [],
    FS_SDCLocObj: [],
  },

  smartDeviceCreateApi: {
    createTurnstile: false,
    createGondola: false,
    createCamera: false,
    createSmartShelf: false,
  },

  locationSteppedGet: [],
  storeDimensionGet: [],
  gondolaStepData: [],
  cameraAndGpuStepData: [],
  cartmanagerStepData: [],

  userRole: [],
  userData: {},
  userKeycloakAttributes: {},
  userKeycloakBeforeModifyAttributes: {},
}

const adminReducer = produce((draft, action) => {
  switch (action.type) {
    case RECEIVE_SIGNIN_DATA:
      draft.checking = action.data
      return draft
    case RECEIVE_PRODUCT_LIST:
      draft.productList = action.data
      return draft
    case RECEIVE_BARCODE_TYPE:
      draft.barCodeType = action.data
      return draft
    case RECEIVE_STORE_TYPE:
      draft.storeIDlist = action.data
      return draft
    case RECEIVE_UNIT_OF_MEASURE:
      draft.unitOfMeasures = action.data
      return draft
    case RECEIVE_PRODUCT_DETAILS:
      draft.productDetailsData = action.data
      return draft
    case RECEIVE_PRODUCT_PARAMS:
      draft.productParams = action.data
      return draft
    case RECEIVE_CATEGORY_LIST:
      draft.catergoryList = action.data
      return draft
    case RECEIVE_SUB_CATEGORY_LIST:
      draft.subCategoryList = action.data
      return draft
    case RECEIVE_PRODUCT_GROUP:
      draft.productGroupList = action.data
      return draft
    case RECEIVE_PRODUCT_GROUP_EDIT_PARAMS:
      draft.productGroupParams = action.data
      return draft
    case RECEIVE_PRODUCT_GROUP_DETAILS:
      draft.groupDetails = action.data
      return draft
    case RECEIVE_PRODUCT_DELETE_GROUP:
      draft.productDelete = action.data
      return draft
    case RECEIVE_ORDERS_LIST:
      draft.ordersList = action.data
      return draft
    case RECEIVE_ORDERS_DETAILS_DATA:
      draft.orderDetails = action.data
      return draft

    case RECEIVE_LOCATION_LIST:
      draft.locationListData = action.data
      return draft
    case SET_SMART_DEVICE_CONFIG_LOC:
      draft.smartDeviceConfig[action.updateId] = action.value
      return draft
    case GET_SELECTED_SDC_LOCATION:
      draft.locationObjectData[action.updateId] = action.value
      return draft
    case SET_SMART_DEVICE_CONFIG_LOC_NAME:
      draft.smartDeviceConfig[action.updateId] = action.value
      return draft
    case SET_CREATE_SDC_SUCCESS_TOGGLE:
      draft.smartDeviceCreateApi[action.updateId] = action.value
      return draft
    case RECEIVE_SELECTED_LOCATION:
      draft.selectedLocationData = action.data
      return draft
    case RECEIVE_TURNSTILE_LIST:
      draft.turstileListData = action.data
      return draft
    case RECEIVE_GONDOLA_LIST:
      draft.gondolaList = action.data
      return draft
    case RECEIVE_GONDOLA_SELECTED:
      draft.gondolaSelected = action.data
      return draft
    case RECEIVE_CAMERA_LIST:
      draft.cameraList = action.data
      return draft
    case RECEIVE_GONDOLA_DETAILS:
      draft.gondolaDetails = action.data
      return draft

    case RECEIVE_LOCATION_SETUP:
      draft.locationSteppedGet = action.data
      return draft
    case RECEIVE_STORE_DIMENSION_STEP:
      draft.storeDimensionGet = action.data
      return draft
    case RECEIVE_GONDOLA_STEP:
      draft.gondolaStepData = action.data
      return draft
    case RECEIVE_CAMERA_AND_GPU_STEP:
      draft.cameraAndGpuStepData = action.data
      return draft
    case RECEIVE_CART_MANAGER_STEP:
      draft.cartmanagerStepData = action.data
      return draft

    case SET_USER_ROLE_INFORMATION:
      draft.userRole = action.data
      return draft
    case SET_USER_DECODE_DATA:
      draft.userData = action.data
      return draft
    case USER_KEYCLOAK_ATTRIBUTES:
      draft.userKeycloakAttributes = action.data
      return draft
    case USER_KEYCLOAK_BEFORE_MODIFY_ATTRIBUTES:
      draft.userKeycloakBeforeModifyAttributes = action.data
      return draft
    case GET_INVENTORY_LOCATION:
      draft.inventoryObjectData = action.data
      return draft
    case TRAINING_PRODUCTS_INFO:
      draft.trainingProductInfo = action.data
      return draft
    default:
      return draft
  }
}, initialState)

export default adminReducer

/* eslint-disable import/no-anonymous-default-export */
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import axios from "axios"
import { cloneDeep, get, isEmpty } from "lodash"
import { API_KC_URL, API_UM_URL, API_USER_URL } from "../../utils/url"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}
const { headerAxios, API_BASE_URL, SaaSCommonAxios } = ServiceAPI
const authHeaderAxios = axios.create()

const fetchAccessToken = () => {
  return window.localStorage.getItem("token")
}

export default {
  createBusinessUsers: (body) => {
    return headerAxios.post(
      API_UM_URL + API_END_POINTS.createBusinessUser,
      body
    )
  },
  updateBusinessUsers: (body, userId) => {
    return headerAxios.put(
      API_UM_URL + API_END_POINTS.updateBusinessUser + "/" + userId,
      body
    )
  },
  updateTenantAdminUsers: (body, userId) => {
    return headerAxios.put(
      API_UM_URL + API_END_POINTS.updateBusinessUser + "/" + userId,
      body
    )
  },
  getAllActiveRoles: () => {
    let tenantId =
      localStorage.getItem("tenantId") === null
        ? ""
        : localStorage.getItem("tenantId")
    return headerAxios.get(
      API_UM_URL + API_END_POINTS.getActiveRolesByTenant + "/" + tenantId
    )
  },

  getRoles: (data) => {
    const pageLimit = get(data, "limit", 10)
    const page = get(data, "page", 1)
    let roleName = ""
    let accessType
    let isEditable

    data?.filter?.forEach((d) => {
      if (d.field === "roleName") {
        roleName = d.value
      }
      if (d.field === "accessType") {
        accessType = d.value === "terminal" || false
      }
      if (d.field === "isRowEditable") {
        isEditable = d.value === "true" || false
      }
    })

    const sortPara = Object?.keys(data?.sort || {})?.[0] || "createdAt"
    const sortValue = Object?.values(data?.sort || {})?.[0] || -1

    return headerAxios.get(API_UM_URL + API_END_POINTS.getRoles, {
      params: {
        limit: pageLimit,
        page,
        roleName,
        roleId: "",
        isEditable,
        sortPara,
        sortValue,
        accessType,
      },
    })
  },

  getRolesTenant: (data) => {
    const pageLimit = get(data, "limit", 10)
    const page = get(data, "page", 1)
    let roleName = ""
    let accessType
    let isEditable
    let isActive
    data?.filter?.forEach((d) => {
      if (d.field === "roleName") {
        roleName = d.value
      }
      if (d.field === "accessType") {
        accessType = d.value
      }
      if (d.field === "isRowEditable") {
        isEditable = d.value
      }
      if (d.field === "isActive") {
        isActive = d.value
      }
    })

    const sortPara = Object?.keys(data?.sort)?.[0] || "createdAt"
    const sortValue = Object?.values(data?.sort)?.[0] || -1

    let tenantId =
      localStorage.getItem("tenantId") === null
        ? ""
        : localStorage.getItem("tenantId")

    return headerAxios.post(
      API_UM_URL + API_END_POINTS.getRolesByTenantId + "/" + tenantId,
      {
        limit: pageLimit,
        page,
        roleName,
        roleId: "",
        sortPara,
        sortValue,
        accessType,
        isEditable,
        isActive,
      }
    )
  },
  getBusinessUsers: (body) => {
    let payload = cloneDeep(body)
    payload?.filter?.forEach((b) => {
      if (b.field === "accessType") {
        payload.accessType = b.value
      }
    })
    payload.filter = payload.filter.filter((f) => f.field !== "accessType")

    if (Object.keys(payload.sort || {})?.[0] === "accessType") {
      payload.sort = { accessType: Object.values(payload.sort || {})?.[0] }
    }
    return headerAxios.post(
      API_UM_URL + API_END_POINTS.getBusinessUsers,
      payload
    )
  },

  createTenantUsers: (body) => {
    return SaaSCommonAxios.post(
      API_UM_URL + API_END_POINTS.createTenantUser,
      body
    )
  },
  getTenantsUsers: (body) => {
    return headerAxios.post(API_UM_URL + API_END_POINTS.getTenantsUsers, body)
  },
  generateOtp: (body) => {
    return authHeaderAxios.post(API_BASE_URL + API_END_POINTS.generateOtp, body)
  },

  verifyOtp: (body) => {
    return authHeaderAxios.post(API_BASE_URL + API_END_POINTS.verifyOtp, body)
  },
  updatePassword: (body) => {
    return authHeaderAxios.post(
      API_UM_URL + API_END_POINTS.updatePassword,
      body
    )
  },
  verifyPassword: (body) => {
    return authHeaderAxios.post(
      API_USER_URL + API_END_POINTS.verifyPassword,
      body,{
        headers: {
          "X-Tenant-ID": localStorage.getItem("tenantId"),
          "Authorization": `Bearer ${fetchAccessToken()}`,
        },
      })
  },
  //generate forgot password
  getForgetPasswordLink: (body) => {
    return authHeaderAxios.post(
      API_USER_URL + API_END_POINTS.getForgetPasswordLink,
      body
    )
  },  
  getAuthToken: (token) => {
    return authHeaderAxios.get(API_UM_URL + API_END_POINTS.getAuthToken + token)
  },
  resetPassword: (body) => {
    return authHeaderAxios.post(API_UM_URL + API_END_POINTS.resetPassword, body)
  },
  updateTenantAddress: (body, tenantId) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.updateTenantAddress + "/" + tenantId,
      body
    )
  },
  getRetailersList: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.getRetailersList,
      body
    )
  },
  getTenantDetailWithAdmin: (TenantID) => {
    return SaaSCommonAxios.get(
      API_UM_URL + API_END_POINTS.getTenantDetailWithAdmin + "/" + TenantID
    )
  },
  getUserDetail: (id) => {
    return headerAxios.get(
      API_UM_URL + API_END_POINTS.getBusinessUserbyId + "/" + id
    )
  },
  getUserInfo: () => {
    return headerAxios.get(
      API_UM_URL + API_END_POINTS.getUserinfo
    )
  },

  //Token Auth
  getTokenDetails: () => {
    return headerAxios.get(API_UM_URL + API_END_POINTS.getTokenAuth)
  },

  updateTenantDetail: (body, tenantId) => {
    return SaaSCommonAxios.post(
      API_UM_URL + API_END_POINTS.updateTenantDetail + "/" + tenantId,
      body
    )
  },

  createTenantRole: (roleName = "", accessType, isActive) => {
    let tenantId =
      localStorage.getItem("tenantId") === null
        ? ""
        : localStorage.getItem("tenantId")
    let createdBy = localStorage.getItem("userName")

    return headerAxios.post(API_UM_URL + API_END_POINTS.createTenantRole, {
      tenantId,
      roleName,
      createdBy,
      updatedBy: createdBy,
      owner: "tenant",
      isEditable: true,
      accessType,
      setFlag: true,
      isActive,
    })
  },

  deleteRole: (roleId) => {
    return headerAxios.delete(
      `${API_UM_URL}${API_END_POINTS.deleteRole}/${roleId}`
    )
  },
  deleteUser: (user_id) => {
    return headerAxios.delete(
      `${API_UM_URL}${API_END_POINTS.deleteUser}/${user_id}`
    )
  },
  updateRoleAttributes: (data) => {
    return headerAxios.put(
      `${API_UM_URL}${API_END_POINTS.updateRoleAttributes}`,
      data
    )
  },

  getAdminRoles: (data) => {
    const pageLimit = get(data, "limit", 10)
    const page = get(data, "page", 1)
    let roleName = ""
    let accessType
    let isEditable
    data?.filter?.forEach((d) => {
      if (d.field === "roleName") {
        roleName = d.value
      }
      if (d.field === "isRowEditable") {
        isEditable = d.value
      }
      if (d.field === "accessType") {
        accessType = d.value
      }
    })
    const sortPara = Object?.keys(data?.sort || {})?.[0] || "createdAt"
    const sortValue = Object?.values(data?.sort || {})?.[0] || -1
    return headerAxios.get(API_UM_URL + API_END_POINTS.getAdminRoles, {
      params: {
        limit: pageLimit,
        page,
        roleName,
        isEditable,
        accessType,
        sortPara,
        sortValue,
      },
    })
  },
  createSAASCustomRole: (roleName = "", accessType, isActive) => {
    let createdBy = localStorage.getItem("userName")
    return headerAxios.post(API_UM_URL + API_END_POINTS.createAdminRole, {
      roleName,
      createdBy,
      updatedBy: createdBy,
      owner: "saas",
      isEditable: true,
      setFlag: true,
      accessType: "portal",
    })
  },
  updateSAASCustomRoleAttributes: (data) => {
    return headerAxios.put(
      `${API_UM_URL}${API_END_POINTS.updateAdminRoleAttributes}`,
      data
    )
  },
  deleteAdminRole: (roleId) => {
    return headerAxios.delete(
      `${API_UM_URL}${API_END_POINTS.deleteAdminRole}/${roleId}`
    )
  },
  getSAASBusinessUsers: (body) => {
    let payload = cloneDeep(body)
    payload = {
      ...payload,
      accessType: "portal",
    }
    return headerAxios.post(API_UM_URL + API_END_POINTS.getAdminUsers, payload)
  },
  createAdminUser: (body) => {
    return headerAxios.post(API_UM_URL + API_END_POINTS.createAdminUser, body)
  },
  getAdminUserDetail: (id) => {
    return headerAxios.get(
      API_UM_URL + API_END_POINTS.getAdminBusinessUserById + "/" + id
    )
  },
  updateAdminBusinessUsers: (body, userId) => {
    return headerAxios.put(
      API_UM_URL + API_END_POINTS.updateAdminUser + "/" + userId,
      body
    )
  },
  deleteAdminUser: (user_id) => {
    return headerAxios.delete(
      `${API_UM_URL}${API_END_POINTS.deleteAdminUser}/${user_id}`
    )
  },
  autheticatePassword: (password) => {
    return headerAxios.post(
      `${API_UM_URL}${API_END_POINTS.autheticateUser}`,
      {
        "password": password,
        "user_name": localStorage.getItem('userEmail')
      }
    )
  }
}
